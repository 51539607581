import React, { Component } from "react";
import styled from "styled-components";
import SpaceMarker from "../components-shared/SpaceMarker";
import TableMarker from "../components-shared/TableMarker";
import { Floor, TableTag, SpaceTag } from "../types/floorplan";

interface IProps {
  forwardedRef: any;
  currentFloor: Floor;
  zoomLevel: number;
  initialFit: "contain" | "height";
  imageMargin?: number;
  tableTags: TableTag[];
  onImageClick: (e: React.MouseEvent) => void;
  onMarkerClick: (e: React.MouseEvent, tag: SpaceTag | TableTag) => void;
}

const HiddenImage = styled.img`
  position: absolute;
  top: -999999px;
  left: -9999999px;
`;

const NonZoomableContainer = styled.div`
  width: 100%;
  height: 100%;
`;

const ZoomableContainer = styled.div`
  display: inline-block;
`;

const Image = styled.img`
  display: inline-block;
  width: ${(props) => props.width}px;
`;

// Adapted from https://stackoverflow.com/a/6565988
const calculateRatio = (iw: number, ih: number, cw: number, ch: number) => {
  const ir = iw / ih;
  const cr = cw / ch;
  const iw2 = cr > ir ? (iw * ch) / ih : cw;
  //const ih2 = cr > ir ? ch : (ih * cw/iw);
  return iw2 / iw;
};

type AreaSize = {
  width: number;
  height: number;
};

interface IState {
  imageSize: AreaSize;
  zoomableContainerSize: AreaSize;
}

class FloorImageContent extends Component<IProps, IState> {
  private outerContainerRef: React.RefObject<HTMLDivElement>;
  private imageRef: React.RefObject<HTMLImageElement>;

  constructor(props: IProps) {
    super(props);
    this.outerContainerRef = React.createRef();
    this.imageRef = React.createRef();
    this.recalculateImageWidth = this.recalculateImageWidth.bind(this);
    this.state = {
      imageSize: { width: 1, height: 1 },
      zoomableContainerSize: { width: 1, height: 1 },
    };
  }

  componentDidMount() {
    window.addEventListener("resize", this.recalculateImageWidth);
    this.recalculateImageWidth();
  }

  componentDidUpdate(prevProps: IProps) {
    if (
      prevProps.currentFloor.image.url !== this.props.currentFloor.image.url ||
      prevProps.zoomLevel !== this.props.zoomLevel
    ) {
      this.recalculateImageWidth();
    }
  }

  componentWillUnmount() {
    window.removeEventListener("resize", this.recalculateImageWidth);
  }

  recalculateImageWidth() {
    const imageMargin = this.props.imageMargin || 0;
    const outerContainer = this.outerContainerRef.current;
    const image = this.imageRef.current;
    if (!outerContainer || !image || !image.naturalWidth || !image.naturalHeight) {
      return;
    }

    const outerContainerArea = outerContainer.getBoundingClientRect();
    const ratio =
      this.props.initialFit === "contain"
        ? calculateRatio(
            image.naturalWidth,
            image.naturalHeight,
            outerContainerArea.width,
            outerContainerArea.height,
          )
        : outerContainerArea.height / image.naturalHeight;

    const imageSize: AreaSize = {
      width: ratio * this.props.zoomLevel * image.naturalWidth,
      height: ratio * this.props.zoomLevel * image.naturalHeight,
    };
    this.setState({
      imageSize,
      zoomableContainerSize: {
        width: Math.max(imageSize.width, outerContainerArea.width) + imageMargin,
        height: Math.max(imageSize.height, outerContainerArea.height) + imageMargin,
      },
    });
  }

  render() {
    const {
      forwardedRef,
      currentFloor,
      tableTags,
      onImageClick,
      onMarkerClick,
      children,
      imageMargin,
    } = this.props;

    return (
      <NonZoomableContainer ref={this.outerContainerRef}>
        <HiddenImage src={currentFloor.image.url} ref={this.imageRef} />
        <ZoomableContainer>
          <div
            style={{
              margin: imageMargin ? imageMargin : 0,
              width: this.state.zoomableContainerSize.width,
              height: this.state.zoomableContainerSize.height,
            }}
          >
            <div
              style={{
                position: "relative",
                width: this.state.imageSize.width,
                height: this.state.imageSize.height,
              }}
            >
              <Image
                onClick={onImageClick}
                src={currentFloor.image.url}
                alt="floor"
                ref={forwardedRef}
                width={this.state.imageSize.width}
                height={this.state.imageSize.height}
                onLoad={this.recalculateImageWidth.bind(this)}
              />

              {currentFloor.spaces.map((tag) => (
                <SpaceMarker
                  tag={tag}
                  key={tag.id || Math.random().toString(36).substring(7)}
                  onClick={(e) => onMarkerClick(e, tag)}
                  zoom={1}
                />
              ))}

              {tableTags.map((tag) => (
                <TableMarker
                  tag={tag}
                  key={tag.id || Math.random().toString(36).substring(7)}
                  onClick={(e) => onMarkerClick(e, tag)}
                  zoom={1}
                />
              ))}
              {children}
            </div>
          </div>
        </ZoomableContainer>
      </NonZoomableContainer>
    );
  }
}

export default FloorImageContent;
