import { GeneralInfo } from "./api";

export enum FloorSize {
  SMALL = "small",
  LARGE = "large",
}

export const FloorSizeValues = [FloorSize.SMALL, FloorSize.LARGE];

export type Company = {
  name: string;
};

export type Move = {
  id: string;
  name: string;
  company: Company;
  generalInfos: GeneralInfo[];
  locales: string[];
  defaultLocale: string;
  stickersNumber: number;
  logoUrl: string;
  hideFloorSelection: boolean;
};

export type Floor = {
  id: string;
  title: string;
  image: FloorImage;
  labelColor: string;
  spaces: SpaceTag[];
  floorSize: FloorSize;
  isDefault: boolean;
};

export type FloorImage = {
  title: string;
  url: string;
};

// Space can have several owners.
export type SpaceTag = {
  id?: string;
  title: string;
  positionX: number;
  positionY: number;
  openSpace: boolean;
  tables: TableTag[];
};

export const isSpaceTag = (x: SpaceTag | TableTag): x is SpaceTag => {
  return !!(x as SpaceTag).title;
};

// Table can have only one owner.
export type TableTag = {
  id?: string;
  space: string;
  letter: string;
  positionX: number;
  positionY: number;
};

export const isTableTag = (x: SpaceTag | TableTag): x is TableTag => {
  return !!(x as TableTag).space;
};

// Owner is usually a person or department.
// It can also just identify a space like "IT Storage"
// NOTE: Assigned owner should always have at least space defined!
export type Owner = {
  id?: string;
  key: string; // Just for react, because we don't always have an ID
  name: string;
  stickersNumber: number | null;
  floor: Floor | null;
  space: SpaceTag | null;
  table: TableTag | null;
};

export function isAssignedOwner(o: Owner) {
  /*
    Accessing o.table and o.stickersNumber is a hack, so that MobX observers
    react to changes in those as well. Normally @computed values are re-calculated
    only when data used in the calculations changes. In out case we compute
    the output array based on just o.floor and o.space, but in our views we
    are interested in o.table and o.stickersNumber as well.

    @todo: If you know a cleaner way on how to fix this, feel free to do so.
  */
  const floor = o.floor;
  const space = o.space;
  const table = o.table;
  const sn = o.stickersNumber;
  return (
    floor !== null &&
    space !== null &&
    (table !== null || table === null) &&
    (sn !== null || sn === null)
  );
}

export function isAssignedTo(o: Owner, s: SpaceTag) {
  /*
    Accessing o.table and o.stickersNumber is a hack, so that MobX observers
    react to changes in those as well. Normally @computed values are re-calculated
    only when data used in the calculations changes. In out case we compute
    the output array based on just o.floor and o.space, but in our views we
    are interested in o.table and o.stickersNumber as well.

    @todo: If you know a cleaner way on how to fix this, feel free to do so.
  */
  const floor = o.floor;
  const space = o.space;
  const table = o.table;
  const sn = o.stickersNumber;
  return (
    floor !== null &&
    space !== null &&
    space!.id === s.id &&
    (table !== null || table === null) &&
    (sn !== null || sn === null)
  );
}

export type Label = {
  ownerKey: string;
  name: string;
  floor: string;
  space: string;
  table: string;
  color: string;
  count: number | null;
  roomNumber: string;
  companyName: string;
};

export type Option<T = string> = {
  value: T;
  label: string;
  disabled?: boolean;
};

export enum FloorPlanEditState {
  Upload = "floor plan",
  Mark = "areas",
  Connect = "people",
  Finish = "labels",
}

export enum MarkingType {
  Space = "space",
  Table = "table",
}

export enum UserType {
  RPM = "relokatorPM",
  CPM = "clientPM",
  END = "endUser",
}
