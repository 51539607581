import { Company } from "./floorplan";

export interface FloorLayoutBase {
  id: string;
  floor_name: string;
  url: string;
}

export interface FloorLayout extends FloorLayoutBase {
  markers: FloorMarker[];
  filename: string;
  labelColor?: string;
  floorSize: string;
  isDefault: boolean;
}

export type FloorMarker = {
  id?: string;
  moveLayoutId?: string;
  markerType: string;
  title: string;
  coordinates: {
    positionX: number;
    positionY: number;
    openSpace?: boolean;
    space?: string;
  };
  users?: FloorMarkerUser[];
};

export type FloorMarkerUser = {
  id: string;
  name: string;
  stickersNumber: number | null;
};

export type FloorMarkerOwner = {
  id: string;
  name: string;
  stickersNumber: number | null;
  floorId: string;
  spaceId: string;
  tableId: string | null;
};

export type ScheduleEvent = {
  date_from: string;
  date_to: string | null;
  description: string;
};

export type WritableMove = {
  stickersNumber?: number;
  unassignedPeople?: string[];
};

export type GeneralInfo = {
  title: string;
  content: string;
};

export type ContactInfo = {
  email: string | null;
  job_title: string | null;
  name: string;
  phone: string | null;
};

export const isGeneralInfoArray = (x: GeneralInfo[] | ContactInfo): x is GeneralInfo[] => {
  return !!(x as GeneralInfo[]);
};

export const isContactInfo = (x: GeneralInfo[] | ContactInfo): x is ContactInfo => {
  return !!(x as ContactInfo).name;
};

export type FAQElement = {
  question: string;
  answer: string;
};

export type Feedback = {
  name: string;
  email: string;
  phone: string;
  feedback_type: FeedbackType;
  question: string;
};

export enum FeedbackType {
  Feedback = "feedback",
  Question = "question",
  Error = "error",
}

export interface MoveApiResponse {
  id: string;
  name: string;
  company: Company;
  key_facts: GeneralInfo[];
  locales: string[];
  defaultLocale: string;
  stickersNumber: number;
  hideFloorSelection: boolean;
}

export enum LoginResult {
  LoggedIn = "loggedin",
  NewPassword = "newpassword",
  ReloginNeededWithPassword = "reloginneededwithpassword",
  ReloginNeededWithEmailAndPassword = "reloginneededwithemailandpassword",
  ReloginNeededWithEmail = "reloginneededwithemail",
}

export interface LoginResponse {
  result: LoginResult;
  newPassword?: string;
}

export interface ColorResponse {
  moveId: string;
  primaryColor: string;
  darkerPrimaryColor: string;
  secondaryColor: string;
  darkerSecondaryColor: string;
  backgroundColor: string;
  headingColor: string;
}

export interface UploadedDocument {
  id: string;
  originalFilename: string;
  createdAt: Date;
}
